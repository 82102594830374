
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import router from "@/router";
import Auth from "@/lib/Auth";

export default Vue.extend({
  name: "LerEMV",

  data() {
    return {
      dialogRevisaoTransferPix: false,

      ModuloInfo: [] as any,
      emv: "",
      alert: {} as Alert,
      loadingData: true,
      btnloading: false,
      btnDisabled: true,
      dialogPixCopyPaste: false,
      isMobile: false,
      valorPagamento: 0,
      curStep: 2,
      lang: navigator.language,
      errors: [],
      moment: moment,

      breadcrumbs: [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.PixArea"),
          disabled: false,
          href: "/pix",
        },
        {
          text: this.$t("components.preCadastro.template.form.PayPix"),
          disabled: true,
          href: "/pix/menuPix",
        },
      ],
    };
  },

  created() {
    this.isMobile = this.detectMobile();
    if (!Auth.mfaEnabled) {
      router.push("/");
    } else {
      this.moduloInfo();
    }
    this.updateBreadcrumbs();
  },

  watch: {
    '$i18n.locale': {
      handler() {
        this.updateBreadcrumbs();
      },
      immediate: true,
    },
  },

  methods: {
    detectMobile() {
      return screen.width <= 760;
    },

    moduloInfo() {
      sessionStorage.removeItem("emvData");
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/financeiro/pix/info")
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    ckLenghtEMV() {
      this.showAlert();
      if (this.emv.length > 50) {
        this.btnDisabled = false;
      }
    },

    validaEMV() {
      this.showAlert();
      sessionStorage.removeItem("emvData");
      if (this.emv.length < 50) {
        this.showAlert(true, "Informe o código do QRCode para prosseguir");
      }
      Overlay.show();
      this.btnloading = true;
      Api.post("/v2/client/financeiro/pix/qrcode/read", {
        emv: this.emv,
      })
        .then((response) => {
          sessionStorage.setItem("emvData", response.data.body);
          this.$nextTick(() => {
            router.push("/pix/transferir/emv/process?id=" + response.data.body);
          });
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            this.showAlert(true, data.body.error);
          }
        })
        .finally(() => {
          Overlay.hide();
          this.btnloading = false;
        });
    },

    resetModulo() {
      this.emv = "";
      sessionStorage.removeItem("emvData");
    },

    showAlert(show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.PixArea"),
          disabled: false,
          href: "/pix",
        },
        {
          text: this.$t("components.preCadastro.template.form.PayPix"),
          disabled: true,
          href: "/pix/menuPix",
        },
      ];
    },
  },
});
