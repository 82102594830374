
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";


interface Breadcrumb {
  text: string;
  disabled: boolean;
  href: string;
}
export default Vue.extend({
  name: "homePix",

  data() {
    return {
      ModuloInfo: [],
      loadingData: true,
      dialogPixKeys: false,
      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
      breadcrumbs: [] as Breadcrumb[],
      hasBassBanprimeAccount: false,
      bassBanprimeAccountInfo: {} as any,
      minhaschaves: [
      {
          "keyType": "EVP",
          "key": "02c832b6-f7ea-4eb6-b1dd-2561ac1f618b",
          "createdAt": "2025-01-02T13:07:37.190Z",
          "updatedAt": "2025-01-02T13:07:37.190Z"
        },
        {
          "keyType": "EVP",
          "key": "39780c9b-eb67-458b-a6a3-5078a165f758",
          "createdAt": "2025-01-02T13:07:39.330Z",
          "updatedAt": "2025-01-02T13:07:39.330Z"
        },
        {
          "keyType": "EVP",
          "key": "0a131b51-58d0-4eb7-9303-583b03dafd79",
          "createdAt": "2025-01-02T13:07:41.517Z",
          "updatedAt": "2025-01-02T13:07:41.517Z"
        },
        {
          "keyType": "EVP",
          "key": "1d90aba1-084d-4c65-bf0a-e13c3d3ad2e0",
          "createdAt": "2025-03-27T11:50:00.630Z",
          "updatedAt": "2025-03-27T11:50:00.630Z"
        }
      ] as any
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.updateBreadcrumbs();
    const us = Auth.getUser();
    this.bassBanprimeAccountInfo = us.data?.bassInfo
    if (us.data?.bassInfo.hasBass) {
      this.hasBassBanprimeAccount = true;
    }
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home") as string,
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.PixArea") as string,
          disabled: true,
          href: "/pix",
        },
      ];
    },

    getDicEntries() {

      if (!this.hasBassBanprimeAccount) {
        Snackbar.show("Conta sem BASS registrado", "error");
        return false;
      }
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/financeiro/pix/minhaschaves")
        .then((response) => {
          this.minhaschaves = response.data.body.rows;
          this.loadingData = false;
          this.dialogPixKeys=true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;

          if (status != 200) {
           Snackbar.show(response.data?.error,"error");
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    doCopy(str: string) {
   
       navigator.clipboard.writeText(str).then(() => {
        Snackbar.show(String(this.$t('homePix.dialogMyDictEntries.copyConfirm')));
      });
    },

  },
  watch: {
    '$i18n.locale'(newLocale: string) {
      this.updateBreadcrumbs();
    }
  },
});
