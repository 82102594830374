
import Vue from "vue";
import moment from "moment";
import { Overlay } from "@/lib/Overlay";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";

export default Vue.extend({
  name: "homeMenuPix",

  data() {
    return {
      ModuloInfo: [],
      emv: "",
      alert: {},
      loadingData: true,
      btnloading: false,
      btnDisabled: true,
      dialogPixCopyPaste: false,
      isMobile: false,
      lang: navigator.language,
      errors: [],
      moment: moment,
      breadcrumbs: [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.PixArea"),
          value: "pix",
          disabled: false,
          href: "/pix",
        },
        {
          text: this.$t("components.preCadastro.template.form.PayPix"),
          disabled: true,
          href: "/pix/menuPix",
        },
      ],
      mfaEnabled: true,
      hasInfoSituacaoConta: false,
      situacaoConta: {} as any,
      loadedData: false,
    };
  },
  created() {
    sessionStorage.removeItem("emvData");
    this.isMobile = this.detectMobile();
    this.getSituacaoConta();
    this.updateBreadcrumbs();
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateBreadcrumbs();
      },
      immediate: true,
    },
  },
  methods: {
    detectMobile() {
      return screen.width <= 760;
    },
    getSituacaoConta() {
      Overlay.show();
      Api.get("/v2/client/dashboard/statusConta")
        .then((response) => {
          this.situacaoConta = response.data.body;
          this.hasInfoSituacaoConta = true;
          this.loadedData = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.PixArea"),
          value: "pix",
          disabled: false,
          href: "/pix",
        },
        {
          text: this.$t("components.preCadastro.template.form.PayPix"),
          disabled: true,
          href: "/pix/menuPix",
        },
      ];
    },
  },
});
