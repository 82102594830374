import Vue from "vue";
import Auth from "@/lib/Auth";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/account/Login.vue";
import LoginNoRecaptcha from "@/views/account/Login.NoRecaptcha.vue";
import RegistroDispositivo from "@/views/account/RegistroDispositivo.vue";
import RecuperarSenha from "@/views/account/RecuperarSenha.vue";
import RedefinirSenha from "@/views/account/RedefinirSenha.vue";
import RedefinirPin from "@/views/account/ResetPIN.vue";
import CriarConta from "@/views/account/CriarConta.vue";
import PreCadastro from "@/views/account/PreCadastro.vue";
import HomeCartao from "@/views/cartao/Cartao.vue";
import GetAfiliado from "@/views/account/GetAfiliado.vue";
import Home from "@/views/Home.vue";
import Movimento from "@/views/financeiro/Movimento.vue";
import MovimentoConsolidado from "@/views/financeiro/MovimentoConsolidado.vue";
import ComprovanteSaque from "@/views/comprovantes/Saques.vue";
import ComprovanteTranserencia from "@/views/comprovantes/Transferencias.vue";
import ComprovanteBoleto from "@/views/comprovantes/Boletos.vue";
import AdicionarSaldoTED from "@/views/financeiro/adicionarSaldoTED.vue";
import AdicionarSaldoBoleto from "@/views/financeiro/adicionarSaldoBoleto.vue";
import EncerrarConta from "@/views/account/EncerrarConta.vue";
import AdicionarSaldoPix from "@/views/financeiro/adicionarSaldoPix.vue";
import Transferencias from "@/views/financeiro/Transferencias.vue";
import TransferenciasPendentes from "@/views/financeiro/TransferenciasPendentes.vue";
//import Saques from "@/views/financeiro/Saques.vue";
import Perfil from "@/views/profile/Perfil.vue";
import PerfilEditDadosBasicos from "@/views/profile/PerfilEditDadosBasicos.vue";
import PerfilEditDadosEndereco from "@/views/profile/PerfilEditDadosEndereco.vue";
import Documentacao from "@/views/profile/Documentos.vue";
import HomeRecargas from "@/views/servicos/recargas/HomeRecargas.vue";
import RecargaCelular from "@/views/servicos/recargas/Celular.vue";
import RecargaJogos from "@/views/servicos/recargas/Jogos.vue";
import RecargaTv from "@/views/servicos/recargas/Tv.vue";
import RecargaDigital from "@/views/servicos/recargas/Digital.vue";
import HomeDepositar from "@/views/financeiro/homeDepositar.vue";
import MeuQRcode from "@/views/financeiro/meuQrCode.vue";
import RelatorioPix from "@/views/financeiro/RelatorioPix.vue";
import RelatorioPixEnviados from "@/views/financeiro/RelatorioPixEnviados.vue";

import HomePix from "@/views/financeiro/homePix.vue";
import HomeServicos from "@/views/servicos/Servicos.vue";
import PagamentoContas from "@/views/servicos/PagamentoContas.vue";
import SegurancaHome from "@/views/seguranca/Seguranca.vue";
import PagarPIX from "@/views/public/PagarPix.vue";
import CobrancaBolePix from "@/views/public/CobrancaBolePix.vue";
import HomeIntegracoesV1 from "@/views/integracoes/IntegracoesV1.vue";
import HomeIntegracoesV2 from "@/views/integracoes/IntegracoesV2.vue";
import PixPendentes from "@/views/financeiro/PixPendentes.vue";
import ValidarEmail from "@/views/account/ValidarEmail.vue";
import CobrancasClientes from "@/views/cobrancas/clientes/Clientes.vue";
import CriarCobranca from "@/views/cobrancas/CriarCobranca.vue";
import CobrancasClientesPerfil from "@/views/cobrancas/clientes/ClientePerfil.vue";
import Cobrancas from "@/views/cobrancas/Cobrancas.vue";
import DetalheCobranca from "@/views/cobrancas/DetalheCobranca.vue";
import CheckoutTransfer from "@/views/checkout/TransferenciaAPI.vue";
import Colaboradores from "@/views/Colaboradores.vue";
import TransferenciasPixChave from "@/views/financeiro/pix/TransferenciasPixChave.vue";
import HomeRelatorios from "@/views/financeiro/homeRelatorios.vue";
import ResumoMovimentacao from "@/views/financeiro/ResumoMovimentacao.vue";
import ResumoAnual from "@/views/financeiro/ResumoAnual.vue";
import HomeMenuPix from "@/views/financeiro/pix/homeMenuPix.vue";
import LerEMV from "@/views/financeiro/pix/LerEMV.vue";
import TransferirPixEMV from "@/views/financeiro/pix/TransferenciasPixEMV.vue";
import ComparativoPeriodo from "@/views/financeiro/reports/PixComparativoPeriodo.vue";
import ConfiguracoesPix from "@/views/financeiro/pix/Configuracoes.vue";
import MinhasChavesPix from "@/views/financeiro/pix/MinhasChaves.vue";
import PixInfractions from "@/views/financeiro/pix/infractions/PixInfractions.vue";
import HomeCripto from "@/views/cripto/HomeCripto.vue";
import Swaps from "@/views/cripto/swaps/Swaps.vue";
import SwapsSell from "../views/cripto/swaps/SwapsSell.vue";
import SwapsInvoices from "@/views/cripto/swaps/SwapsInvoices.vue";
import MainGGR from "@/views/ggr/MainGGR.vue";
import AfiliadosGGR from "@/views/ggr/Afiliados.vue";
import ExtratoGGR from "@/views/ggr/Extrato.vue";
import ResumoGGR from "@/views/ggr/Resumo.vue";
import HomeMenuAfiliados from "@/views/HomeMenuAfiliados.vue";
import HomeAfiliados from "@/views/afiliados/HomeAfiliados.vue";
import Afiliados from "@/views/afiliados/Afiliados.vue";
import Comissionamento from "@/views/afiliados/Comissionamento.vue";
import Tst from "@/views/tst.vue";
import i18n from "@/i18n";
import ImportarClientes from "@/views/cobrancas/clientes/ImportarClientes.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    meta: {
      auth: false,
    },
    component: () => import("@/layouts/Login.vue"),
    children: [
      {
        path: "/login",
        alias: "/login/",
        component: Login,
        meta: {
          title: "components.login.pagetitle",
        },
      },
      {
        path: "/test-login-631008a7-1705-4b73-a9ae-a16c0860c7f2",
        alias: "/test-login-631008a7-1705-4b73-a9ae-a16c0860c7f2/",
        component: LoginNoRecaptcha,
        meta: {
          title: "components.login.pagetitle",
        },
      },
      {
        path: "/validar-email/:id/",
        alias: "/validar-email/:id",
        component: ValidarEmail,
        meta: {
          title: "components.validateEmail.pagetitle",
        },
      },
      {
        path: "/conta/confirmacoes/email/:id/",
        alias: "/conta/confirmacoes/email/:id",
        component: ValidarEmail,
        meta: {
          title: "components.validateEmail.pagetitle",
        },
      },
      {
        path: "/device",
        alias: "/device/",
        component: RegistroDispositivo,
        meta: {
          title: "components.RegistroDispositivo.pagetitle",
        },
      },
      {
        path: "/recuperar",
        alias: "/recuperar/",
        component: RecuperarSenha,
        meta: {
          title: "components.recuperarSenha.pagetitle",
        },
      },
      {
        path: "/redefinir/:token",
        alias: "/redefinir/:token/",
        component: RedefinirSenha,
        meta: {
          title: "components.RedefinirSenha.pagetitle",
        },
      },
      {
        path: "/redefinir-pin/:token",
        alias: "/redefinir-pin/:token/",
        component: RedefinirPin,
        meta: {
          title: "components.RedefinirPin.pagetitle",
        },
      },
      {
        path: "/registro/:id",
        alias: "/registro/:id/",
        component: CriarConta,
        meta: {
          title: "components.CriarConta.pagetitle",
        },
      },
      {
        path: "/cadastro",
        alias: "/cadastro/",
        component: PreCadastro,
        meta: {
          title: "components.preCadastro.pagetitle",
        },
      },
      {
        path: "/encerrar/:id",
        alias: "/encerrar/:id/",
        component: EncerrarConta,
        meta: {
          title: "components.EncerrarConta.pagetitle",
        },
      },
      /*{
        path: "/link/:id",
        alias: "/link/:id/",
        component: GetAfiliado,
        meta: {
          title: "Afiliado",
        },
       
      },
       */
    ],
  },
  {
    path: "/comprovantes",
    meta: {
      auth: false,
    },
    component: () => import("@/layouts/ComprovantesPrint.vue"),
    children: [
      {
        path: "/comprovantes/transferencia/:id",
        alias: "/comprovantes/transferencia/:id/",
        component: ComprovanteTranserencia,
        meta: {
          title: "components.ComprovanteTranserencia.pagetitle",
        },
      },
      {
        path: "/comprovantes/saque/:id",
        alias: "/comprovantes/saque/:id/",
        component: ComprovanteSaque,
        meta: {
          title: "components.ComprovanteSaque.pagetitle",
        },
      },
      {
        path: "/comprovantes/boleto/:id",
        alias: "/comprovantes/boleto/:id/",
        component: ComprovanteBoleto,
        meta: {
          title: "components.ComprovanteBoleto.pagetitle",
        },
      },
    ],
  },
  {
    path: "/pagar",
    meta: {
      auth: false,
    },
    component: () => import("@/layouts/ComprovantesPrint.vue"),
    children: [
      {
        path: "/pagar/pix/:id",
        alias: "/pagar/pagar/:id/",
        component: PagarPIX,
        meta: {
          title: "components.PagarPIX.pagetitle",
        },
      },
    ],
  },
  {
    path: "/cob",
    meta: {
      auth: false,
    },
    component: () => import("@/layouts/ComprovantesPrint.vue"),
    children: [
      {
        path: "/cob/f/:id",
        alias: "/cob/f/:id/",
        component: CobrancaBolePix,
        meta: {
          title: "components.CobrancaBolePix.pagetitle",
        },
      },
    ],
  },
  {
    path: "/checkout",
    meta: {
      auth: false,
    },
    component: () => import("@/layouts/Checkout.vue"),
    children: [
      {
        path: "/checkout/pay/transfer/:id",
        alias: "/checkout/pay/transfer/:id/",
        component: CheckoutTransfer,
        meta: {
          title: "components.CheckoutTransfer.pagetitle",
        },
      },
    ],
  },
  {
    path: "/",
    meta: {
      auth: true,
    },
    component: () => import("@/layouts/BaseApp.vue"),
    children: [
      {
        path: "",
        component: Home,
        meta: {
          title: "components.Home.pagetitle",
        },
      },
      {
        path: "/transferencias/interna",
        component: Transferencias,
        meta: {
          title: "components.Transferencias.pagetitle",
        },
      },
      {
        path: "/transferencias/pendentes",
        component: TransferenciasPendentes,
        meta: {
          title: "components.TransferenciasPendentes.pagetitle",
        },
      },
      {
        path: "/pix/pendentes",
        component: PixPendentes,
        meta: {
          title: "components.PixPendentes.pagetitle",
        },
      },
      {
        path: "/pix/configuracoes",

        component: ConfiguracoesPix,
        meta: {
          title: "components.ConfiguracoesPix.pagetitle",
        },
      },
      {
        path: "/pix/minhaschaves",

        component: MinhasChavesPix,
        meta: {
          title: "components.MinhasChavesPix.pagetitle",
        },
      },
      /*  {
        path: "/saques",

        component: Saques,
        meta: {
          title: "Saques",
        },
      },*/
      {
        path: "/pix",
        component: HomePix,
        meta: {
          title: "components.HomePix.pagetitle",
        },
      },
      {
        path: "/pix/infractions",
        alias: "/pix/infractions/",
        component: PixInfractions,
        meta: {
          auth: true,
          title: "components.PixInfractions.title",
        },
      },
      {
        path: "/pix/meu-qr-code",
        component: MeuQRcode,
        meta: {
          title: "components.MeuQRcode.pagetitle",
        },
      },
      {
        path: "/pix/relatorios/emitidos",
        component: RelatorioPix,
        meta: {
          title: "components.RelatorioPix.pagetitle",
        },
      },
      {
        path: "/pix/relatorios/enviados",
        component: RelatorioPixEnviados,
        meta: {
          title: "components.RelatorioPixEnviados.pagetitle",
        },
      },
      {
        path: "/pix/relatorios/comparativoPeriodo",
        component: ComparativoPeriodo,
        meta: {
          title: "components.ComparativoPeriodo.pagetitle",
        },
      },
      {
        path: "/movimento",
        component: Movimento,
        meta: {
          title: "components.Movimento.pagetitle",
        },
      },
      {
        path: "/movimento/consolidado",
        component: MovimentoConsolidado,
        meta: {
          title: "consolidatedStatement.title",
        },
      },
      {
        path: "/carteira/adicionar/ted",
        component: AdicionarSaldoTED,
        meta: {
          title: "components.AdicionarSaldoTED.pagetitle",
        },
      },
      {
        path: "/carteira/adicionar/boleto",
        component: AdicionarSaldoBoleto,
        meta: {
          title: "components.AdicionarSaldoBoleto.pagetitle",
        },
      },
      {
        path: "/carteira/adicionar/pix",
        component: AdicionarSaldoPix,
        meta: {
          title: "components.AdicionarSaldoPix.pagetitle",
        },
      },
      {
        path: "/afiliados",
        component: HomeMenuAfiliados,
        meta: {
          title: "components.HomeMenuAfiliados.pagetitle",
        },
      },
      {
        path: "/afiliados/afiliados",
        component: HomeAfiliados,
        meta: {
          title: "components.HomeAfiliados.pagetitle",
        },
      },
      {
        path: "/afiliados/afiliados/pessoas",
        component: Afiliados,
        meta: {
          title: "components.Afiliados.pagetitle",
        },
      },
      {
        path: "/afiliados/afiliados/comissionamento",
        component: Comissionamento,
        meta: {
          title: "components.Comissionamento.pagetitle",
        },
      },
      {
        path: "/carteira",
        component: HomeDepositar,
        meta: {
          title: "components.HomeDepositar.pagetitle",
        },
      },
      {
        path: "/perfil",
        component: Perfil,
        meta: {
          title: "components.Perfil.pagetitle",
        },
      },
      {
        path: "/perfil/dados-basicos",
        component: PerfilEditDadosBasicos,
        meta: {
          title: "components.PerfilEditDadosBasicos.pagetitle",
        },
      },
      {
        path: "/perfil/dados-endereco",
        component: PerfilEditDadosEndereco,
        meta: {
          title: "components.PerfilEditDadosEndereco.pagetitle",
        },
      },
      {
        path: "/perfil/documentos",
        component: Documentacao,
        meta: {
          title: "components.Documentacao.pagetitle",
        },
      },
      {
        path: "/recargas",
        component: HomeRecargas,
        meta: {
          title: "components.HomeRecargas.pagetitle",
        },
      },
      {
        path: "/recargas/celular",
        component: RecargaCelular,
        meta: {
          title: "components.RecargaCelular.pagetitle",
        },
      },
      {
        path: "/recargas/jogos",
        component: RecargaJogos,
        meta: {
          title: "components.RecargaJogos.pagetitle",
        },
      },
      {
        path: "/recargas/tv",
        component: RecargaTv,
        meta: {
          title: "components.RecargaTv.pagetitle",
        },
      },
      {
        path: "/recargas/digital",
        component: RecargaDigital,
        meta: {
          title: "components.RecargaDigital.pagetitle",
        },
      },
      {
        path: "/servicos",
        component: HomeServicos,
        meta: {
          title: "components.HomeServicos.pagetitle",
        },
      },
      {
        path: "/pagamentos",
        component: PagamentoContas,
        meta: {
          title: "components.PagamentoContas.pagetitle",
        },
      },
      {
        path: "/seguranca",
        component: SegurancaHome,
        meta: {
          title: "components.SegurancaHome.pagetitle",
        },
      },
      {
        path: "/cartao",
        component: HomeCartao,
        meta: {
          title: "components.HomeCartao.pagetitle",
        },
      },
      {
        path: "/integracoes",
        component: HomeIntegracoesV2,
        meta: {
          title: "components.HomeIntegracoesV2.pagetitle",
        },
      },
      {
        path: "/integracoes/v1",
        component: HomeIntegracoesV1,
        meta: {
          title: "components.HomeIntegracoesV1.pagetitle",
        },
      },
      {
        path: "/colaboradores",
        component: Colaboradores,
        meta: {
          title: "components.Colaboradores.pagetitle",
        },
      },
      {
        path: "/pix/homePix",
        component: HomePix,
        meta: {
          title: "components.HomePix.pagetitle",
        },
      },
      {
        path: "/pix/menuPix",
        component: HomeMenuPix,
        meta: {
          title: "components.HomeMenuPix.pagetitle",
        },
      },
      {
        path: "/pix/transferir/chave",
        component: TransferenciasPixChave,
        meta: {
          title: "components.TransferenciasPixChave.pagetitle",
        },
      },
      {
        path: "/pix/transferir/emv",
        component: LerEMV,
        meta: {
          title: "components.LerEMV.pagetitle",
        },
      },
      {
        path: "/pix/transferir/emv/process",
        component: TransferirPixEMV,
        meta: {
          title: "components.TransferirPixEMV.pagetitle",
        },
      },
      {
        path: "/relatorios",
        component: HomeRelatorios,
        meta: {
          title: "components.HomeRelatorios.pagetitle",
        },
      },
      {
        path: "/relatorios/resumo",
        component: ResumoMovimentacao,
        meta: {
          title: "components.ResumoMovimentacao.pagetitle",
        },
      },
      {
        path: "/relatorios/resumo/anual",
        component: ResumoAnual,
        meta: {
          title: "components.ResumoAnual.pagetitle",
        },
      },
      {
        path: "/cobrancas",
        component: Cobrancas,
        meta: {
          title: "components.Cobrancas.pagetitle",
        },
      },
      {
        path: "/cobrancas/criar",
        component: CriarCobranca,
        meta: {
          title: "components.CriarCobranca.pagetitle",
        },
      },
      {
        path: "/cobrancas/detalhes/:id",
        component: DetalheCobranca,
        meta: {
          title: "components.DetalheCobranca.pagetitle",
        },
      },
      {
        path: "/cobrancas/clientes",
        component: CobrancasClientes,
        meta: {
          title: "components.CobrancasClientes.pagetitle",
        },
      },
      {
        path: "/cobrancas/clientes/:id",
        component: CobrancasClientesPerfil,
        meta: {
          title: "components.CobrancasClientesPerfil.pagetitle",
        },
      },
      {
        path: "/cobrancas/importar/clientes",
        component: ImportarClientes,
        meta: {
          title: "components.CobrancasClientesPerfil.pagetitle",
        },
      },
      {
        path: "/cripto",
        component: HomeCripto,
        meta: {
          title: "components.HomeCripto.pagetitle",
        },
      },
      {
        path: "/cripto/swaps/buy",
        component: Swaps,
        meta: {
          title: "components.Swaps.pagetitle",
        },
      },
      {
        path: "/cripto/swaps/sell",
        component: SwapsSell,
        meta: {
          title: "components.preCadastro.template.form.SwapsSellTitle",
        },
      },
      {
        path: "/cripto/invoices",
        component: SwapsInvoices,
        meta: {
          title: "components.SwapsInvoices.pagetitle",
        },
      },
      {
        path: "/ggr",
        component: MainGGR,
        meta: {
          title: "components.MainGGR.pagetitle",
        },
      },
      {
        path: "/ggr/afiliados",
        component: AfiliadosGGR,
        meta: {
          title: "components.AfiliadosGGR.pagetitle",
        },
      },
      {
        path: "/ggr/extrato",
        component: ExtratoGGR,
        meta: {
          title: "components.ExtratoGGR.pagetitle",
        },
      },
      {
        path: "/ggr/resumo",
        component: ResumoGGR,
        meta: {
          title: "components.ResumoGGR.pagetitle",
        },
      },
      {
        path: "/tst",
        component: Tst,
        meta: {
          title: "components.Tst.pagetitle",
        },
      },
    ],
  },

  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (!Auth.isLogged()) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});
router.afterEach((to, from) => {
  if (to.meta && to.meta.title) {
    document.title = i18n.t(to.meta.title) + " - " + process.env.VUE_APP_NAME;
  }
});

export default router;
