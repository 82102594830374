import {
  TenantAppDomainEnum,
  TenantConfig,
  TenantConfigRoutes,
  TenantContents,
  UsernameTypes,
} from "@/services/multi-tenancy/types";

import tenantContentJson from "./content/hubchain.json";
import { TenantThemes } from "@/services/multi-tenancy/themes";
// import favicon from "../../assets/favicons/hubchainbank.png";

const _permissions: any[] /* PermissionType[] */ =
  []; /*  APP_PERMISSIONS_ARRAY */
const _excludedPermissions: any[] /* PermissionType[] */ = [];

const routes: TenantConfigRoutes = {
  dashboard: true,
};

const config: TenantConfig = {
  name: `${TenantAppDomainEnum?.EZZEBANK}`.split(".")[0],
  appDomain: TenantAppDomainEnum.EZZEBANK,
  theme: TenantThemes.Ezzebank,
  title: "Ezzebank - Internet Banking",
  // favicon: Asset.fromModule(favicon).uri,
  username: {
    login: UsernameTypes.CPF,
    recoveryPassword: UsernameTypes.EMAIL,
  },
  routes: routes,
  permissions: _permissions.filter(
    (permission) => !_excludedPermissions.includes(permission)
  ),
  contents: tenantContentJson as TenantContents,
};

export default config;
