import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCompositionAPI from "@vue/composition-api";
import Swal from "sweetalert2";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { VueMaskDirective } from "v-mask";
// import "@/assets/styles/styles.css";
import { MultiTenancyService } from "./services/multi-tenancy";
import(
  `@/assets/tenants/${MultiTenancyService.getTenant().name}/styles/styles.css`
);

Vue.directive("mask", VueMaskDirective);

const hostname = window.location.hostname;
const kind = "app";
if (hostname.endsWith(".web.app") || hostname.endsWith(".firebaseapp.com")) {
  let _subdomain = hostname.split(".")[0];
  switch (_subdomain) {
    case `ezzebank-${kind}-production`:
      _subdomain = `${kind}`;
      break;
    case `ezzebank-${kind}-sandbox`:
      _subdomain = `${kind}-sandbox`;
      break;
    case `ezzebank-${kind}-development`:
      _subdomain = `${kind}-development`;
      break;
    default:
      _subdomain = `${kind}`;
  }
  window.location.href = `https://${_subdomain}.ezzebank.com`;
}

const firebaseConfig = {
  apiKey: "AIzaSyCE4faM0vfn1MnUVZQxVuHCf280d0G1EuA",
  authDomain: "ezzebank.firebaseapp.com",
  projectId: "ezzebank",
  storageBucket: "ezzebank.appspot.com",
  messagingSenderId: "580937014992",
  appId: "1:580937014992:web:ad27e88871cb76427008d5",
  measurementId: "G-FWXVJPK03C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Vue.use(VueCompositionAPI, Swal);

Vue.config.productionTip = false;
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
