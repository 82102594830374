
  import Vue, { PropType } from "vue";
  import Api from "@/services/Api";
  import { AxiosResponse } from "axios";
  import { Overlay } from "@/lib/Overlay";
  import { Snackbar } from "@/lib/Snackbar";
  import moment from "moment";
  import PixInfractions_Defense from "./PixInfractions_Defense.vue";

    interface DefenseResult {
        success: boolean;
        defense_id: string;
    }

    interface Infraction {
        id: string;
        status: string;
        response_deadline: string;
        infraction_type: string;
        is_gambling?: string;
        end_to_end_id: string;
        referal_transaction_id: string;
        referal_external_id: string;
        created_at: string;
        amount: string;
        payer_document: string;
        payer_name: string;
        reported_by: string;
        report_detail?: string;
        analysis_details?: string;
        updated_at: string;
        defense_histories?: any[];
    }
  
  export default Vue.extend({
    name: "PixInfractions_Details",
    
    props: {
        infractionId: {
            type: String,
            required: true
        },
        initialInfraction: {
            type: Object as PropType<Infraction | null>,
            default: null
        }
    },

    components: {
      PixInfractions_Defense
    },
    
    data() {
      return {
        loading: true,
        infraction: null as Infraction | null,
        
        // Para o dialog de defesa
        defenseDialog: false,
        defenseText: "",
        defenseFiles: [] as File[],
        defenseErrors: [] as string[],
        fileErrors: [] as string[],
        sendingDefense: false,
        defenseComponentDialog: false,
      };
    },
    
    created() {
        if (this.initialInfraction) {
            this.infraction = { ...this.initialInfraction }; 
        }
        this.loadInfractionDetails();
    },
    
    methods: {
      loadInfractionDetails() {
        this.loading = true;
        
        Api.get(`/v2/client/financeiro/pix/infractions?acao=detail&id=${this.infractionId}`)
          .then((response) => {
            this.infraction = response.data.body.infraction;
          })
          .catch((error: any) => {
            const response = error.response as AxiosResponse;
            const status = response.status;
            const data = response.data;
            
            Snackbar.show(
              data.body?.error || "Erro ao carregar detalhes da infração", 
              "error"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      },

      formatDate(dateString: string) {
        return moment(dateString).format("DD/MM/YYYY HH:mm");
      },
      
      formatCurrency(value: string) {
        const numberValue = parseFloat(value);
        return numberValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      },
      
      formatDocument(doc: string) {
        if (doc.length === 11) {
          return doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        }
        else if (doc.length === 14) {
          return doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        }
        return doc;
      },
      
      getStatusText(status: string) {
        switch (status) {
          case 'WAITING_DEFENSE':
            return this.$t('components.PixInfractions.template.waitingDefense') || 'Aguardando Defesa';
          case 'WAITING_ANALYSIS':
            return this.$t('components.PixInfractions.template.waitingAnalysis') || 'Aguardando Análise';
          case 'AGREED':
            return this.$t('components.PixInfractions.template.agreed') || 'Concordado';
          case 'DISAGREED':
            return this.$t('components.PixInfractions.template.disagreed') || 'Discordado';
          default:
            return status;
        }
      },
      
      getStatusColor(status: string) {
        switch (status) {
          case 'WAITING_DEFENSE':
            return 'warning';
          case 'WAITING_ANALYSIS':
            return 'info';
          case 'AGREED':
            return 'success';
          case 'DISAGREED':
            return 'error';
          default:
            return 'grey';
        }
      },

      getStatusIcon(status: string): string {
        switch (status) {
        case 'WAITING_DEFENSE':
            return 'mdi-shield-alert-outline';
        case 'WAITING_ANALYSIS':
            return 'mdi-clipboard-check-outline';
        case 'AGREED':
            return 'mdi-check-circle-outline';
        case 'DISAGREED':
            return 'mdi-close-circle-outline';
        default:
            return 'mdi-help-circle-outline';
        }
    },

        getInfractionTypeIcon(type: string): string {
            switch (type) {
            case 'FRAUD':
                return 'mdi-alert-circle-outline';
            case 'AML_CTF':
                return 'mdi-shield-alert-outline';
            case 'REFUND_REQUEST':
                return 'mdi-cash-refund';
            default:
                return 'mdi-help-circle-outline';
            }
        },
      
      getReportedByText(reportedBy: string) {
        switch (reportedBy) {
          case 'DEBITED_PARTICIPANT':
            return 'Participante Debitado';
          case 'CREDITED_PARTICIPANT':
            return 'Participante Creditado';
          default:
            return reportedBy;
        }
      },
      
      isDeadlineClose(deadline: string) {
        const deadlineDate = moment(deadline);
        const now = moment();
        return deadlineDate.diff(now, 'days') <= 3;
      },

      getRemainingDaysText(deadline: string) {
        const deadlineDate = moment(deadline);
        const now = moment();
        const days = deadlineDate.diff(now, 'days');
        
        if (days < 0) {
          return this.$t('components.PixInfractions.template.deadlineExpired') || 'Prazo expirado';
        } else if (days === 0) {
          return this.$t('components.PixInfractions.template.lastDay') || 'Último dia';
        } else {
            return this.$t('components.PixInfractions.template.daysRemaining', { days }) || `${days} ${days === 1 ? 'dia' : 'dias'} restantes`;
        }
      },

      openAttachment(url: string) {
        window.open(url, '_blank');
      },
      
      openDefenseDialog() {
        this.defenseDialog = true;
        this.defenseText = "";
        this.defenseFiles = [];
        this.defenseErrors = [];
        this.fileErrors = [];
      },

      submitDefense() {
        this.defenseErrors = [];
        this.fileErrors = [];
        if (!this.defenseText.trim()) {
            this.defenseErrors.push('O texto da defesa é obrigatório');
            return;
        }
        
        this.sendingDefense = true;
        
        const formData = new FormData();
        formData.append('defense', this.defenseText);
        
        if (this.defenseFiles.length > 0) {
            const convertAndSend = async () => {
            try {
                const filesPromises = this.defenseFiles.map(file => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                    if (reader.result && typeof reader.result === 'string') {
                        resolve(reader.result.split(',')[1]);
                    } else {
                        reject(new Error('Falha ao ler arquivo'));
                    }
                    };
                    reader.onerror = () => reject(reader.error);
                    reader.readAsDataURL(file);
                });
                });
                
                const base64Files = await Promise.all(filesPromises);
                formData.append('files', JSON.stringify(base64Files));
                
                this.sendToAPI(formData);
            } catch (error) {
                console.error('Erro ao converter arquivos:', error);
                this.sendingDefense = false;
                Snackbar.show('Erro ao processar os arquivos', 'error');
            }
            };
            
            convertAndSend();
        } else {
            // Envia para a API sem arquivos
            this.sendToAPI(formData);
        }
        },

        sendToAPI(formData: FormData) {
            Api.post(`/v2/client/financeiro/pix/infractions?acao=defend&id=${this.infractionId}`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
                .then(() => {
                Snackbar.show('Defesa enviada com sucesso', 'success');
                this.defenseDialog = false;
                this.loadInfractionDetails();
                })
                .catch((error: any) => {
                const response = error.response as AxiosResponse;
                const data = response.data;
                
                if (data.body && data.body.errors) {
                    this.defenseErrors = data.body.errors.defense || [];
                    this.fileErrors = data.body.errors.files || [];
                } else {
                    Snackbar.show(
                    data.body?.error || 'Erro ao enviar defesa', 
                    'error'
                    );
                }
                })
                .finally(() => {
                this.sendingDefense = false;
                });
            },
        
        openDefenseComponent() {
            this.defenseComponentDialog = true;
        },

        handleDefenseSubmitted(result: DefenseResult) {
            this.defenseComponentDialog = false;
            Snackbar.show(`Defesa ${result.defense_id} enviada com sucesso`, 'success');
            this.loadInfractionDetails();
        },

        getInfractionTypeColor(type: string): string {
            switch (type) {
                case 'FRAUD':
                return 'red';
                case 'AML_CTF':
                return 'purple';
                case 'REFUND_REQUEST':
                return 'blue'; 
                default:
                return 'grey';
            }
            },

            getInfractionTypeText(type: string): string {
            switch (type) {
                case 'FRAUD':
                    return String(this.$t('components.PixInfractions.template.infractionTypeSelect.fraud') || 'FRAUDE');
                case 'AML_CTF':
                    return String(this.$t('components.PixInfractions.template.infractionTypeSelect.amlCtf') || 'AML/CTF');
                case 'REFUND_REQUEST':
                    return String(this.$t('components.PixInfractions.template.infractionTypeSelect.refundRequest') || 'PEDIDO DE REEMBOLSO');
                default:
                return type;
            }
        }
    },
    computed: {
        canDefendInfraction(): boolean {
            if (!this.infraction || typeof this.infraction !== 'object') return false;
            
            const hasCorrectStatus = this.infraction.status === 'WAITING_DEFENSE';
            const deadline = new Date(this.infraction.response_deadline);
            const now = new Date();
            const deadlineValid = deadline > now;
            
            return hasCorrectStatus && deadlineValid;
        }
    }

  });
  