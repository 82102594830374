export enum AvailableThemes {
  Ezzebank = "Ezzebank",
  Banprime = "Banprime",
  Hubchain = "Hubchain",
  GrooveBank = "GrooveBank",
}

export interface TenantConfigThemeFieldBasicAttributes {
  dark: boolean;
  color: string;
}

export interface TenantConfigTheme {
  dark: boolean;
  vTextField: TenantConfigThemeFieldBasicAttributes;
  vBtn: TenantConfigThemeFieldBasicAttributes;
  vRadio: TenantConfigThemeFieldBasicAttributes;
  vSwitch: TenantConfigThemeFieldBasicAttributes;
  vAvatar: TenantConfigThemeFieldBasicAttributes;
}

// @reference: https://vuetifyjs.com/en/styles/colors/#material-colors
export const TenantThemes: {
  [theme in AvailableThemes]: TenantConfigTheme;
} = {
  [AvailableThemes.Ezzebank]: {
    dark: true,
    vTextField: {
      dark: true,
      color: "white",
    },
    vBtn: {
      dark: true,
      color: "pink accent-2",
    },
    vRadio: {
      dark: true,
      color: "pink accent-2",
    },
    vSwitch: {
      dark: true,
      color: "pink accent-2",
    },
    vAvatar: {
      dark: true,
      color: "purple darken-3",
    },
  },
  [AvailableThemes.Banprime]: {
    // Rosa vibrante (236, 28, 106) – Aproxima-se de "Pink 700" (#D81B60). pink-darken-1
    // Roxo escuro (98, 48, 147) – Aproxima-se de "Deep Purple 800" (#512DA8). deep-purple-darken-2
    dark: false,
    vTextField: {
      dark: false,
      color: "black",
    },
    vBtn: {
      dark: true,
      color: "pink accent-2",
    },
    vRadio: {
      dark: true,
      color: "pink accent-2",
    },
    vSwitch: {
      dark: true,
      color: "pink accent-2",
    },
    vAvatar: {
      dark: true,
      color: "purple darken-3",
    },
  },
  [AvailableThemes.Hubchain]: {
    // Azul escuro (58, 88, 155) – Aproxima-se de "Indigo 700" (#303F9F). indigo-darken-2
    // Azul ligeiramente mais claro (58, 85, 154) – Também se aproxima de "Indigo 700" (#303F9F). indigo-darken-2
    dark: false,
    vTextField: {
      dark: false,
      color: "black",
    },
    vBtn: {
      dark: true,
      color: "indigo darken-2",
    },
    vRadio: {
      dark: true,
      color: "indigo darken-2",
    },
    vSwitch: {
      dark: true,
      color: "indigo darken-2",
    },
    vAvatar: {
      dark: true,
      color: "indigo darken-2",
    },
  },
  [AvailableThemes.GrooveBank]: {
    // Verde vibrante (93, 224, 5) – Aproxima-se de "Light Green A400" (#76FF03). light-green-accent-3
    dark: false,
    vTextField: {
      dark: false,
      color: "black",
    },
    vBtn: {
      dark: false,
      color: "light-green accent-3",
    },
    vRadio: {
      dark: true,
      color: "light-green accent-3",
    },
    vSwitch: {
      dark: true,
      color: "light-green accent-3",
    },
    vAvatar: {
      dark: true,
      color: "light-green darken-3",
    },
  },
};
