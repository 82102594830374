
  import Vue from "vue";
  import moment from "moment";
  
  export default Vue.extend({
    name: "PixInfractions_Table",
    
    props: {
      desserts: {
        type: Array,
        required: true
      },
      loading: {
        type: Boolean,
        default: false
      },
      pagination: {
        type: Object,
        required: true
      },
      searchPerformed: {
        type: Boolean,
        default: false
      }
    },
    
    data() {
      return {
        page: 1,
        headers: [
          { text: "components.PixInfractions.template.headers.status", value: "status", sortable: false, align: "center", width: "80px" },
          { text: "components.PixInfractions.template.headers.createdAt", value: "created_at", sortable: false },
          { text: "components.PixInfractions.template.headers.type", value: "infraction_type", sortable: false },
          
          { text: "components.PixInfractions.template.headers.endToEndId", value: "end_to_end_id", sortable: false },
          { text: "components.PixInfractions.template.headers.amount", value: "amount", sortable: false, align: "right" },
          { text: "components.PixInfractions.template.headers.deadline", value: "response_deadline", sortable: false },
          { text: "components.PixInfractions.template.headers.actions", value: "actions", sortable: false, align: "center", width: "80px" }
        ]
      };
    },
  
    watch: {
      // Sincronizar página com props
      'pagination.page': {
        handler(newPage) {
          this.page = newPage;
        },
        immediate: true
      }
    },
    
    methods: {
      // Formatação de data
      formatDate(dateString: string) {
        return moment(dateString).format("DD/MM/YYYY HH:mm");
      },
      
      // Formatação de moeda
      formatCurrency(value: string) {
        const numberValue = parseFloat(value);
        return numberValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      },
      
      // Emite evento de mudança de página
      handlePageChange(page: number) {
        this.$emit('page-change', page);
      },
      
      // Emite evento para visualizar detalhes
      viewDetails(id: string) {
        this.$emit('view-details', id);
      },
      
      // Define classes para as linhas com base no status
      rowClasses(item: any) {
        if (item.status === 'AGREED') {
          return 'success--text';
        } else if (item.status === 'DISAGREED') {
          return 'error--text';
        } else if (item.status === 'WAITING_DEFENSE') {
          return 'warning--text text--lighten-1';
        }
        return '';
      },

      getInfractionTypeColor(type: string): string {
        switch (type) {
            case 'FRAUD':
            return 'red';
            case 'AML_CTF':
            return 'purple';
            case 'REFUND_REQUEST':
            return 'blue';
            default:
            return 'grey';
        }
        },

        getInfractionTypeText(type: string): string {
        switch (type) {
            case 'FRAUD':
            return 'FRAUDE';
            case 'AML_CTF':
            return 'AML/CTF';
            case 'REFUND_REQUEST':
            return 'PEDIDO DE REEMBOLSO';
            default:
            return type;
        }
        }
    },
    
    computed: {
      // Traduz os cabeçalhos das colunas
      translatedHeaders() {
        let vm = this as any;
        return vm.headers.map((header: { text: string; }) => ({
          ...header,
          text: String(vm.$t(header.text) || header.text),
        }));
      }
    }
  });
  