
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { Login } from "@/models/Login";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Overlay } from "@/lib/Overlay";
import router from "@/router";
import VueRecaptcha from "vue-recaptcha";
import { DeviceUUID } from "device-uuid";
import LocaleSwitcher from "../../components/LocaleSwitcher.vue";

export default Vue.extend({
  name: "Login",
  data: () => ({
    rand: 1,
    isMobile: false,
    windowOverlay: false,
    dialog2FALogin: false,
    uriLogin: "/v2/client/auth",
    showpass: false,
    valid: true,
    form: {} as Login,
    loginError: null,
    passwordError: null,
    btnLoading: false,
    lembraLogin: false,
    hasRegisteredDevice: false,
    clientIp: "0.0.0.0",
    deviceInfo: {},
    sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    // sitekey: process.env.VUE_APP_DEVELOPMENT_RECAPTCHA_SITE_KEY,
  }),
  components: {
    "vue-recaptcha": VueRecaptcha,
    LocaleSwitcher: LocaleSwitcher,
  },
  created() {
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("userAvatar");
    sessionStorage.removeItem("deviceRegisterData");
    this.form.code = "";
    this.isMobile = this.detectMobile();
    if (
      sessionStorage.deviceRegistered == undefined ||
      sessionStorage.deviceRegistered == null
    ) {
      this.hasRegisteredDevice = false;
    } else {
      this.hasRegisteredDevice = true;
    }

    this.rand = new Date().getTime();
    if (localStorage.getItem("lembrarDadosAcesso")) {
      const dadosAcesso = JSON.parse(
        String(localStorage.getItem("lembrarDadosAcesso"))
      );
      this.form.login = dadosAcesso.login;
      this.form.lembrar = true;
      this.lembraLogin = true;
      this.form.dadosLembrar = JSON.parse(
        String(localStorage.getItem("lembrarDadosAcesso"))
      );
      // this.form.login= this.dadosLoginLembrar.login;
      // console.log(this.form.dadosLembrar.avatar);
    }

    this.getUserIP();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    randomNumber: function () {
      return Math.floor(Math.random() * (10 - 1 + 1)) + 1;
    },
    removeLembrar() {
      localStorage.removeItem("lembrarDadosAcesso");
      (this.form.login = ""), (this.lembraLogin = false);
    },
    doLogin(token: string): void {
      this.btnLoading = true;
      Overlay.show();
      Api.post(this.uriLogin, this.form, {
        headers: {
          recaptcha: token,
        },
      })
        .then((response) => {
          Overlay.show();

          sessionStorage.setItem(
            "userData",
            btoa(JSON.stringify(response.data.body.dados))
          );

          //se o dispositivo nao estiver registrado....
          if (!response.data.body.device_info.hasDispositivoDB) {
            /* sessionStorage.setItem(
                "pre_access_token",
                response.data.body.token
              );*/
            sessionStorage.setItem(
              "deviceRegisterData",
              JSON.stringify(response.data.body.device_info)
            );
            router.push("/device");
          } else {
            if (this.form.lembrar) {
              localStorage.setItem(
                "lembrarDadosAcesso",
                JSON.stringify({
                  login: this.form.login,

                  nome: response.data.body.dados.nome,
                  avatar: response.data.body.dados.txtAvatar,
                })
              );
            } else {
              localStorage.removeItem("lembrarDadosAcesso");
            }

            sessionStorage.setItem(
              "userAvatar",
              response.data.body.dados.avatar
            );

            sessionStorage.setItem(
              "_vid",
              response.data.body.device_info.hashDevice
            );
            sessionStorage.removeItem("deviceRegistered");
            Auth.login(response.data.body.token);
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          this.form.code = "";
          if (status === 422) {
            if (data.body.errors.login)
              this.loginError = data.body.errors.login;
            if (data.body.errors.password)
              this.passwordError = data.body.errors.password;
          } else if (status === 302) {
            this.$nextTick(() => {
              const wrapper = this.$refs.code as any;

              window.setTimeout(() => wrapper?.focus(), 0);
            });
            this.uriLogin = "/v2/client/auth/2fa";
            this.dialog2FALogin = true;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
          this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");

          Overlay.hide();
        });
    },
    onSubmit: function () {
      //this.windowOverlay = true;

      (this.$refs.invisibleRecaptcha as HTMLFormElement).execute();
    },
    onVerify: function (response: string) {
      this.doLogin(response);
    },
    onExpired: function () {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      (this.$refs.invisibleRecaptcha as HTMLFormElement).reset(); // Direct call reset method
    },
    cancela2FA() {
      this.uriLogin = "/v2/client/auth";
      this.form.code = "";
      this.dialog2FALogin = false;
      this.form.password = "";
    },

    getUserIP: function () {
      const uuid = new DeviceUUID().get();
      if (
        localStorage.MachineId == undefined ||
        localStorage.MachineId == null
      ) {
        localStorage.setItem("MachineId", uuid);
      }

      this.form.deviceInfo = {
        useragent: navigator.userAgent,
        deviceId: uuid,
        lat_long: "",
        imei: "",
        mac: "",
      };
      /*
      Overlay.show();
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((response) => {
          this.form.deviceInfo = {
            useragent: navigator.userAgent,
            //ip: response.ip,
            lat_long: "",
            imei: "",
            mac: "",
          };

        })
        .finally(() => {
          Overlay.hide();
        });
         */
    },
    verifyWritePass() {
      if(this.form.password != ""){
        this.showpass = false;
        this.passwordError = null;
      } 
    },
    verifyWriteEmail() {
      if(this.form.login != ""){
        this.loginError = null;
      } 
    },
  },
});
