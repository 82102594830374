
  import Vue from "vue";
  import Api from "@/services/Api";
  import { AxiosResponse } from "axios";
  import { Overlay } from "@/lib/Overlay";
  import { Snackbar } from "@/lib/Snackbar";
  import moment from "moment";
  
  
  export default Vue.extend({
    name: "PixInfractions_Defense",
    
    props: {
      infractionId: {
        type: String,
        required: true
      },
      infraction: {
        type: Object,
        default: null
      }
    },
    
    data() {
      return {
        defenseText: "",
        defenseFiles: [] as File[],
        defenseErrors: [] as string[],
        fileErrors: [] as string[],
        isFormValid: false,
        
        sendingDefense: false,
        showPreview: false,
        showConfirmation: false,

        requiredRule: (v: string | undefined): boolean | string => !!v || 'O texto da defesa é obrigatório'
      };
    },
    
    methods: {
      formatDate(dateString: string) {
        return moment(dateString).format("DD/MM/YYYY HH:mm");
      },
      
      formatCurrency(value: string) {
        const numberValue = parseFloat(value);
        return numberValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      },
      
      isDeadlineClose(deadline: string) {
        const deadlineDate = moment(deadline);
        const now = moment();
        return deadlineDate.diff(now, 'days') <= 3;
      },

      getRemainingDaysText(deadline: string) {
        const deadlineDate = moment(deadline);
        const now = moment();
        const days = deadlineDate.diff(now, 'days');
        
        if (days < 0) {
            return this.$t('components.PixInfractions.template.deadlineExpired') || 'Prazo expirado';
        } else if (days === 0) {
            return this.$t('components.PixInfractions.template.lastDay') || 'Último dia';
        } else {
            return this.$t('components.PixInfractions.template.daysRemaining', { days }) || `${days} ${days === 1 ? 'dia' : 'dias'} restantes`;
        }
      },

      getFileIcon(fileName: string) {
        const extension = fileName.split('.').pop()?.toLowerCase();
        
        switch (extension) {
          case 'pdf':
            return 'mdi-file-pdf';
          case 'doc':
          case 'docx':
            return 'mdi-file-word';
          case 'xls':
          case 'xlsx':
            return 'mdi-file-excel';
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'gif':
            return 'mdi-file-image';
          default:
            return 'mdi-file-document-outline';
        }
      },
      
      formatFileSize(bytes: number) {
        if (bytes === 0) return '0 Bytes';
        
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
      },
      
      submitDefense() {
        if (!(this.$refs.defenseForm as any).validate()) {
          return;
        }
        
        this.showConfirmation = true;
      },
      
      confirmAndSendDefense() {
        this.sendingDefense = true;
        
        const formData = new FormData();
        formData.append('defense', this.defenseText);

        if (this.defenseFiles.length > 0) {

          console.log('Arquivos a serem enviados:', this.defenseFiles);
          const filesPromises = this.defenseFiles.map(file => this.fileToBase64(file));
          
          Promise.all(filesPromises)
            .then(base64Files => {
              formData.append('files', JSON.stringify(base64Files));
              this.sendDefenseToAPI(formData);
              console.log('FormData a ser enviado:', {
                defense: this.defenseText,
                filesCount: this.defenseFiles.length,
                filesInfo: this.defenseFiles.map(f => ({ name: f.name, size: f.size, type: f.type }))
              });
            })
            .catch(error => {
              console.error('Erro ao converter arquivos:', error);
              this.sendingDefense = false;
              Snackbar.show('Erro ao processar os arquivos', 'error');
              this.showConfirmation = false;
            });
        } else {
          this.sendDefenseToAPI(formData);
        }
        
        
      },

      fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            // O resultado contém "data:image/png;base64," que precisamos remover
            const base64String = typeof reader.result === 'string' 
              ? reader.result.split(',')[1] 
              : '';
            resolve(base64String);
          };
          reader.onerror = error => reject(error);
        });
      },

      sendDefenseToAPI(formData: FormData) {
        console.log('FormData montado:', {
            defense: formData.get('defense'),
            filesExist: formData.has('files'),
            filesContent: formData.get('files')
        });
        
        Api.post(`/v2/client/financeiro/pix/infractions/${this.infractionId}/defense`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            Snackbar.show('Defesa enviada com sucesso', 'success');
            this.showConfirmation = false;

            this.$emit('defense-submitted', response.data.body);
          })
          .catch((error: any) => {
            const response = error.response as AxiosResponse;
            const data = response.data;
            
            if (data.body && data.body.errors) {
              this.defenseErrors = data.body.errors.defense || [];
              this.fileErrors = data.body.errors.files || [];
            } else {
              Snackbar.show(
                data.body?.error || 'Erro ao enviar defesa', 
                'error'
              );
            }
            
            this.showConfirmation = false;
          })
          .finally(() => {
            this.sendingDefense = false;
          });
      }
    }
  });
  