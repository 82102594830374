
  import Vue from "vue";
  import Api from "@/services/Api";
  import { AxiosResponse } from "axios";
  import { Overlay } from "@/lib/Overlay";
  import { Snackbar } from "@/lib/Snackbar";
  import PixInfractions_Filters from "./PixInfractions_Filters.vue";
  import PixInfractions_Table from "./PixInfractions_Table.vue";
  import PixInfractions_Details from "./PixInfractions_Details.vue";
  
  export interface PixInfraction {
    id: string;
    created_at: string;
    infraction_type: string;
    reported_by: string;
    referal_external_id: string;
    amount: string;
    end_to_end_id: string;
    payer_document: string;
    status: string;
    response_deadline: string;
    updated_at: string;
  }
  
  export interface InfractionFilters {
    status: string;
    infraction_type: string;
    search: string;
    startDate: string;
    endDate: string;
  }
  
  export default Vue.extend({
    name: "PixInfractions",
    components: {
      PixInfractions_Filters,
      PixInfractions_Table,
      PixInfractions_Details
    },
  
    data() {
      return {
        // Dados de infrações e carregamento
        desserts: [] as PixInfraction[],
        loadingDesserts: false,
        searchPerformed: false,
        errorMessage: "",
        hasError: false, 
        
        // Paginação
        pagination: {
          page: 1,
          totalItems: 0,
          totalPages: 0,
          itemsPerPage: 20
        },
        
        // Filtros
        filters: {
          status: "",
          infraction_type: "",
          search: "",
          startDate: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().substr(0, 10),
          endDate: new Date().toISOString().substr(0, 10)
        } as InfractionFilters,
        detailDialog: false,
        selectedInfractionId: "",
        timeout: 0
      };
    },
  
    created() {
      // Carrega inicial
      //this.loadData();
    },
  
    methods: {
      // Carrega os dados das infrações da API
      loadData() {
        // Validar antes de prosseguir
        if (!this.validateDateRange()) {
            return;
        }
        Overlay.show();
        this.loadingDesserts = true;
        this.searchPerformed = true;
        const params = {
            acao: 'list',
            page: this.pagination.page,
            status: this.filters.status,
            dateFrom: this.filters.startDate,
            dateTo: this.filters.endDate
        };      
        const backendParams: Record<string, any> = {
            page: this.pagination.page,
            status: this.filters.status,
            infraction_type: this.filters.infraction_type,
            dateFrom: this.filters.startDate,
            dateTo: this.filters.endDate,
            externalId: this.filters.search,
            endtoEndId: this.filters.search
        };

        Object.keys(backendParams).forEach(key => {
            if (!backendParams[key]) {
            delete backendParams[key];
            }
        });
        
        Api.get("/v2/client/financeiro/pix/infractions", {
            params: {
            acao: 'list',
            ...backendParams
            }
        })
          .then((response) => {
            this.desserts = response.data.body.data as PixInfraction[];
            this.pagination.totalItems = response.data.body.meta.total_itens;
            this.pagination.totalPages = response.data.body.meta.total_pages;
          })
          .catch((error: any) => {
            const response = error.response as AxiosResponse;
            if (response) {
                const status = response.status;
                const data = response.data;
                if (data.body && data.body.error) {
                this.errorMessage = data.body.error;
                } else {
                this.errorMessage = "Ocorreu um erro ao carregar as infrações";
                }
            } else {
                this.errorMessage = "Erro de conexão com o servidor";
            }
            this.hasError = true;
            })
          .finally(() => {
            this.loadingDesserts = false;
            Overlay.hide();
          });
      },

    // Validação do intervalo de datas
    validateDateRange() {
        const startDate = new Date(this.filters.startDate);
        const endDate = new Date(this.filters.endDate);
        
        if (endDate < startDate) {
            Snackbar.show(
                String(this.$t('components.PixInfractions.template.validations.dateEndBeforeStart') || "Data final não pode ser anterior à data inicial"), 
                "error"
            );
            return false;
        }
        
        const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        
        if (diffDays > 15) {
            Snackbar.show(
                String(this.$t('components.PixInfractions.template.validations.dateIntervalTooLong') || "Intervalo entre as datas não pode ser superior a 15 dias"), 
                "error"
            );
            return false;
        }
        
        return true;
    },
  
      // Manipula a mudança de página
      handlePageChange(page: number) {
        this.pagination.page = page;
        this.loadData();
      },
  
      // Manipula o filtro
      handleFilter(filters: InfractionFilters) {
        this.filters = filters;
        this.pagination.page = 1;
        
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
  
        this.timeout = setTimeout(() => {
          this.loadData();
        }, 500);
      },
  
      // Reseta os filtros
      resetFilters() {
        this.filters = {
          status: "",
          infraction_type: "",
          search: "",
          startDate: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substr(0, 10),
          endDate: new Date().toISOString().substr(0, 10)
        };
        this.pagination.page = 1;
        this.loadData();
      },
  
      // Abre o dialog de detalhes
      openDetailDialog(infractionId: string) {
        this.selectedInfractionId = infractionId;
        this.detailDialog = true;
      }
    }
  });
  