import { AvailableThemes, TenantConfigTheme } from "../themes";

export enum TenantAppDomainEnum {
  EZZEBANK = "ezzebank.com",
  BANPRIME = "banprime.com.br",
  HUBCHAIN = "hubchain.com",
  GROOVEBANK = "groovebank.io",
}

export enum UsernameTypes {
  EMAIL,
  CPF,
  CPF_CNPJ,
  EMAIL_CPF_CNPJ,
}

export interface TenantConfigUsername {
  login: UsernameTypes;
  recoveryPassword: UsernameTypes;
}

export interface TenantConfigRoutes {
  dashboard: boolean;
}

export interface TenantContents {
  company: {
    shortName: string;
  };
  "about-us": any;
  "terms-and-conditions": any;
  "politically-exposed": any;
}

export interface TenantConfig {
  name: string;
  appDomain: TenantAppDomainEnum;
  theme: TenantConfigTheme;
  title: string;
  companyName?: string;
  favicon?: any;
  username: TenantConfigUsername;
  routes: TenantConfigRoutes;
  permissions: any[]; // PermissionType[];
  contents: TenantContents;
  stylesCss?: any;
}
