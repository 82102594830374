import {
  TenantAppDomainEnum,
  TenantConfig,
  UsernameTypes,
} from "@/services/multi-tenancy/types";

import tentantConfigDefault from "./__default";

import tenantContentJson from "./content/groovebank.json";
import { TenantThemes } from "@/services/multi-tenancy/themes";
// import favicon from "../../assets/favicons/hubchain.png";

const _excludedPermissions: any[] /* PermissionType[] */ = [];

const config: TenantConfig = {
  ...tentantConfigDefault,
  ...{
    name: `${TenantAppDomainEnum?.GROOVEBANK}`.split(".")[0],
    appDomain: TenantAppDomainEnum.GROOVEBANK,
    theme: TenantThemes.GrooveBank,
    title: "GrooveBank - Internet Banking",
    // favicon: Asset.fromModule(favicon).uri,
    username: {
      ...tentantConfigDefault.username,
      ...{
        login: UsernameTypes.CPF,
        recoveryPassword: UsernameTypes.CPF,
      },
    },
    routes: {
      ...tentantConfigDefault.routes,
      // ...{

      // }
    },
    permissions: tentantConfigDefault.permissions.filter(
      (permission) => !_excludedPermissions.includes(permission)
    ),
    contents: {
      ...tentantConfigDefault.contents,
      ...tenantContentJson,
    },
  },
};

export default config;
