// import Vue from "vue";

import { TenantAppDomainEnum } from "./types";
import { TenantConfig } from "./types";

const tenants: { [key: string]: TenantConfig } = {};

const tenantConfigContext = require.context("@/config/tenants", false, /\.ts$/);

tenantConfigContext.keys().forEach((key: string) => {
  const module = tenantConfigContext(key);
  const _tenantName = key.replace(".ts", "").replace("./", "");
  tenants[_tenantName] = module.default;
  // console.log(_tenantName, tenants[_tenantName]);
});

// const stylesCssContext = require.context(
//   `@/assets/tenants`,
//   true,
//   /\.(css|jpg|png|svg|jpeg)$/
// );

// stylesCssContext.keys().forEach((key: string) => {
//   const module = stylesCssContext(key);
//   const _tenantName = key.split("/")[1];
//   console.log(_tenantName, module);
// });

export class MultiTenancyService {
  private static tenant: { name: string; appDomain: string };
  private static tenantConfig: TenantConfig;

  public static getTenant(): { name: string; appDomain: string } {
    if (!MultiTenancyService.tenant) {
      const _hostname = window.location.hostname;
      const _tenantName = _hostname.split(".")[0].split("-")[0];

      // console.log("..::", _hostname, _tenantName);

      for (const tenantKey of Object.keys(TenantAppDomainEnum) as Array<
        keyof typeof TenantAppDomainEnum
      >) {
        const _appDomain = TenantAppDomainEnum[tenantKey];
        // console.log("..::", { _hostname, _tenantName, tenantKey, _appDomain });
        if (_tenantName === _appDomain.split(".")[0].split("-")[0]) {
          MultiTenancyService.tenant = {
            name: _tenantName?.split(".")[0].toLowerCase(),
            appDomain: _appDomain,
          };
          // console.log(
          //   `..:: [Tenant By Preffix ${_tenantName}-]`,
          //   MultiTenancyService.tenant
          // );
          break;
        }
      }

      if (!MultiTenancyService.tenant) {
        for (const tenantKey of Object.keys(TenantAppDomainEnum) as Array<
          keyof typeof TenantAppDomainEnum
        >) {
          const _appDomain = TenantAppDomainEnum[tenantKey];
          // console.log("..::", { _hostname, _tenantName, tenantKey, _appDomain });
          if (_hostname.endsWith(_appDomain)) {
            MultiTenancyService.tenant = {
              name: (tenantKey as string)?.split(".")[0].toLowerCase(),
              appDomain: _appDomain,
            };
            // console.log(`..:: [Tenant By Suffix *.${_appDomain}]`, MultiTenancyService.tenant);
            break;
          }
        }
      }
    }

    return MultiTenancyService.tenant;
  }

  public static getTenantConfig(): TenantConfig {
    if (!MultiTenancyService.tenantConfig) {
      const _tenant = MultiTenancyService.getTenant() ?? {
        name: `${TenantAppDomainEnum?.EZZEBANK}`.split(".")[0],
        appDomain: TenantAppDomainEnum?.EZZEBANK,
      };
      // console.log(`::getTenantConfig::`, tenants, _tenant);
      const _tenantConfig = tenants[_tenant?.name];
      // console.log(`::getTenantConfig::`, _tenantConfig);
      if (!_tenantConfig) {
        alert(`Tenant '${_tenant?.name}' not found!`);
      }
      if (_tenantConfig?.name === _tenant?.name) {
        MultiTenancyService.tenantConfig = _tenantConfig;
      }
    }

    return MultiTenancyService.tenantConfig;
  }
}
