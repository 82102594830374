
  import Vue from "vue";
  
  export default Vue.extend({
    name: "PixInfractions_Filters",
    
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      filters: {
        type: Object,
        required: true
      }
    },
    
    data() {
      return {
        // Valores dos filtros
        searchQuery: this.filters.search || '',
        selectedInfractionType: this.filters.infraction_type || '',
        selectedStatus: this.filters.status || '',
        startDate: this.filters.startDate || '',
        endDate: this.filters.endDate || '',
        
        
      };
    },
    
    watch: {
      filters: {
        handler(newFilters) {
          this.searchQuery = newFilters.search || '';
          this.selectedInfractionType = newFilters.infraction_type || '';
          this.selectedStatus = newFilters.status || '';
          this.startDate = newFilters.startDate || '';
          this.endDate = newFilters.endDate || '';
        },
        deep: true
      }
    },
    
    methods: {
      handleFiltersChange() {
        // é utilizado como hook para eventos
      },

      applyFilters() {
        const filters = {
          search: this.searchQuery,
          infraction_type: this.selectedInfractionType,
          status: this.selectedStatus,
          startDate: this.startDate,
          endDate: this.endDate
        };
        
        this.$emit('filter', filters);
      },
      
      resetFilters() {
        this.searchQuery = '';
        this.selectedInfractionType = '';
        this.selectedStatus = '';
        this.startDate = new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().substr(0, 10);
        this.endDate = new Date().toISOString().substr(0, 10);
        
        this.$emit('reset-filters');
      }
    }
  });
  